<template>
    <div id="alert-recovery" class="animated fadeInDown">
        <div class="container-alert">
            <img src="@/assets/images/logo/logo_oficial.png" alt="Envíame" class="img-responsive"> 
            <h2 v-if="messageAlert.title">{{ messageAlert.title }}<i class="fa fa-lock" aria-hidden="true"></i></h2>
            <div>
                <div v-if="$slots.default">
                    <slot></slot>
                </div>
                <p v-if="messageAlert.text">{{ messageAlert.text }}</p>
                <div class="d-flex justify-content-center">
                    <img v-if="messageAlert.type === CONDITIONS.SUCCESS" src="@/assets/images/login/vector-success.png" alt="Icono de alerta exito" class="custom-img">
                    <img v-else src="@/assets/images/login/vector-error.png" alt="Icono de alerta error" class="custom-img">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'alert-custom',
  props: ['messageAlert'],
  data () {
    return {
      CONDITIONS: Object.freeze({
        SUCCESS: 'success',
        ERROR: 'error'
      })
    }
  }
}
</script>
<style>
#alert-recovery {
    background-color: #FFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 100vh;
}
.container-alert {
    width: 450px;
}
.container-alert h2, .container-alert p {
    color: #18195E;
}
.container-alert h2{
    margin-bottom: 1rem; 
    font-weight: 600;
}
.container-alert p{
    font-size: 14px;
}
.container-alert img  {
    margin-bottom: 2rem;
    width: 60%;
}
.fa-lock {
    margin-left: 0.5rem;
    color: #F4BB43;
    font-size: 20px;
}
.custom-img {
    width: 40% !important;
    margin-top: 3rem;
}
@media (max-width: 870px) {
    .container-alert {
        width: auto;
        padding: 0 2rem;
    }
    .container-alert h2{
        font-size: 18px;
    }
    
}
</style>