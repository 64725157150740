<template>
    <div>
    <alert-custom v-if="showAlert" :messageAlert="messageAlert">
      <template v-slot v-if="!!messageAlert.error">
        <p class="error-message" v-if="!!messageAlert.invalidOobCode">
          El link que utilizaste ya expiró. Asegúrate de hacer clic en el link del último email recibido. <br><br>
          Si necesitas más ayuda, escríbenos a 
          <b-link href="mailto:support@enviame.io" class="lnk lnk-forgot-mail">
            <a>support@enviame.io</a> 
          </b-link>
        </p>
        <p class="error-message" v-else>
          Se ha producido un error. Por favor, inténtalo de nuevo más tarde. <br><br>
          Si el problema persiste, escríbenos a 
          <b-link href="mailto:support@enviame.io" class="lnk lnk-forgot-mail">
            <a>support@enviame.io</a> 
          </b-link>
          para que podamos ayudarte.
        </p>
      </template>
    </alert-custom>
    <div id="container-recovery-password" class="animated fadeInDown" v-else>
      <div class="container-recovery-password-internal">
        <img src="@/assets/images/logo/logo_oficial.png" alt="Envíame" class="img-responsive"> 
          <h2 class="d-flex align-items-center">Recuperar Contraseña<feather-icon class="icon-lock" icon="LockIcon" size="20" stroke-width="3"/></h2>
          <p>Ingresa tu nueva contraseña</p>
          <validation-observer
            ref="resetForm"
            #default="{invalid}"
        >
            <b-form class="form-reset-password" @submit.prevent="validationForm">
                <b-form-group class="margin-input-email">
                    <validation-provider #default="{ errors }"  name="Password" rules="required">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                            <b-form-input
                            id="password"
                            :disabled="loading"
                            v-model="password"
                            :type="passwordFieldType"
                            class="form-control-merge customize-input"
                            :state="errors.length > 0 ? false:null"
                            name="login-password"
                            :placeholder="$t('Ingrese nueva contraseña')"
                            @keyup="onChangePassword"
                            />
                            <div class="form-tooltip ibox-title custome-box">
                            <h3>Requisitos de contraseña</h3>
                                <ul>
                                    <li>
                                        <feather-icon 
                                            :class="{ 'check-circle-confirm': statusPwd.length }" 
                                            :icon="statusPwd.length ? 'CheckCircleIcon' : 'CircleIcon'" 
                                            size="16"
                                        />&nbsp;&nbsp;<span>Longitud de al menos 10 caracteres</span>
                                    </li>
                                    <li>
                                        <feather-icon 
                                            :class="{ 'check-circle-confirm': statusPwd.capitalLetter }" 
                                            :icon="statusPwd.capitalLetter ? 'CheckCircleIcon' : 'CircleIcon'" 
                                            size="16"
                                            />&nbsp;&nbsp;<span>Debe contener al menos una letra mayúscula</span>
                                    </li>
                                    <li>
                                        <feather-icon 
                                            :class="{ 'check-circle-confirm': statusPwd.lowerCase }" 
                                            :icon="statusPwd.lowerCase ? 'CheckCircleIcon' : 'CircleIcon'" 
                                            size="16"
                                            />&nbsp;&nbsp;<span>Debe contener al menos una letra minúscula</span>
                                    </li>
                                    <li>
                                        <feather-icon 
                                            :class="{ 'check-circle-confirm': statusPwd.number }" 
                                            :icon="statusPwd.number ? 'CheckCircleIcon' : 'CircleIcon'" 
                                            size="16"
                                            />&nbsp;&nbsp;<span>Debe contener al menos un número</span>
                                    </li>
                                    <li>
                                        <feather-icon 
                                            :class="{ 'check-circle-confirm': statusPwd.specialCharacter }" 
                                            :icon="statusPwd.specialCharacter ? 'CheckCircleIcon' : 'CircleIcon'" 
                                            size="16"
                                            />&nbsp;&nbsp;<span>Debe contener al menos un caracter especial (!@#$%^&*)</span>
                                    </li>
                                </ul>
                            </div>
                            <b-input-group-append is-text class="customize-input">
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                            </b-input-group-append>
                        </b-input-group>
                        
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group class="mt-1">
                    <validation-provider #default="{ errors }" name="PasswordConfirm" rules="required">
                        <b-input-group class="input-group-merge mt-2" :class="errors.length > 0 ? 'is-invalid':null">
                            <b-form-input
                            id="passwordConfirm"
                            :disabled="loading"
                            v-model="passwordConfirm"
                            :type="passwordFieldTypeConfirm"
                            class="form-control-merge customize-input"
                            :state="errors.length > 0 ? false:null"
                            name="login-password-confirm"
                            :placeholder="$t('Confirme nueva contraseña')"
                            />
                            <b-input-group-append is-text class="customize-input">
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIconConfirm" @click="togglePasswordVisibilityConfirm"/>
                            </b-input-group-append>
                        </b-input-group>
                        <b-tooltip 
                            id="tooltip-restore-password"
                            ref="tooltipRestorePassword" 
                            target="passwordConfirm" 
                            :disabled.sync="disabledTooltip"
                            placement="bottom">
                            <feather-icon icon="AlertTriangleIcon" class="mr-50 text-danger"/><span>Las contraseñas no coinciden</span>
                        </b-tooltip>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <div id="recaptcha" class="text-center center-recaptcha"></div>
                <b-button class="mt-1 btn-reset" type="submit" block :disabled="invalid || loading">
                    <feather-icon v-if="loading" icon="LoaderIcon" class="spinner"/>
                    {{$t('Aceptar')}}
                </b-button>
            </b-form>
        </validation-observer>
      </div> 
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BLink, BFormGroup, BFormInput, BForm, BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import LoginService from './login.service'
import { environment } from '@/environments/environment'
import AlertCustom from './AlertCustom.vue'

// eslint-disable-next-line no-undef
const googleRecaptcha = grecaptcha.enterprise

export default {
  name: 'restore-password',
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    AlertCustom
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      passwordConfirm: '',
      loading: false,
      // validation
      required,
      attempt: 0,
      tokenRecaptcha: 'norendered',
      widget: null,
      email,
      loginService: new LoginService(this),
      showAlert: false,
      messageAlert: {},
      CONDITIONS: Object.freeze({
        SUCCESS: 'success',
        ERROR: 'error'
      }),
      statusPwd: {
        capitalLetter: false,
        lowerCase: false,
        number: false, 
        specialCharacter: false 
      },
      disabledTooltip: true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon' 
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeOffIcon' : 'EyeIcon' 
    }
  },
  mounted () {
    this.showRecaptcha('recaptcha', this.attempt, 0)  
    const {user, oobCode} = this.$route.query
    this.userEmail = user || null
    this.oobCode = oobCode || null
  },
  methods: {
    onChangePassword (e) {
      if (e.target.value.length > 0) {
        this.passwordValidations(e.target.value)
            
      } else {  
        Object.keys(this.statusPwd).forEach(key => {
          this.$set(this.statusPwd, key, false)
        })
      }    
    },
    passwordValidations (password) {
      const lengthRegex = new RegExp('.{10,}')
      const specialCharacterRegex = new RegExp('[!@#$%^&*]')
      const capitalLetterRegex = new RegExp('[A-Z]')
      const lowerCaseregex = new RegExp('[a-z]')
      const numberRegex = new RegExp('[0-9]')

      this.statusPwd = {
        length: lengthRegex.test(!!password ? password.trim() : ''),
        capitalLetter: capitalLetterRegex.test(password),
        lowerCase: !!password ? lowerCaseregex.test(password) : false,
        number: numberRegex.test(password), 
        specialCharacter: specialCharacterRegex.test(password) 
      }
    },
    verifyCallback (token) {
      this.tokenRecaptcha = token
    },
    showRecaptcha (element, attempt, maxAttempt) {
      if (attempt >= maxAttempt) {
        if (this.tokenRecaptcha === 'norendered') {
          this.widget = googleRecaptcha.render(element, {
            'sitekey' : environment.recaptchaKey,
            'callback' : (token) => this.verifyCallback(token)
          })
        } else if (!this.widegt) googleRecaptcha.reset(this.widget)
        this.tokenRecaptcha = 'notoken'
      }
    },
    validationForm() {
      this.$refs.resetForm.validate().then(success => {
        if (success && Object.values(this.statusPwd).every(value => value === true)) {
          this.attempt++
          if (this.password === this.passwordConfirm) {
            if (this.tokenRecaptcha === 'norendered') {
              this.loading = true
              this.restorePassword()
            } else if (this.tokenRecaptcha === 'notoken') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title:'Oops',
                  icon: 'warning',
                  variant: 'danger',
                  text: 'Debe marcar la casilla "No soy un robot"'
                }
              })
            } else {
              this.loading = true
              this.loginService.callService('validateRecaptcha', null, { response: this.tokenRecaptcha, expectedAction: 'restore-password' })
                .then(resp => {
                  if (resp.data.tokenProperties?.valid) {
                    this.restorePassword()
                  } else {
                    this.showRecaptcha('recaptcha', this.attempt, 0)
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title:'Oops',
                        icon: 'warning',
                        variant: 'danger',
                        text: `Hemos detectado actividad sospechosa para recuperar tu cuenta. Por favor reintenta y si sigue pasando, contactate con nosotros. \n \n Problema: ${resp.reasons[0] || 'INVALID_REASON'}`
                      }
                    })
                    this.loading = false
                  }
                })
                .catch(err => {
                  this.showRecaptcha('recaptcha', this.attempt, 0)
                  this.loading = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title:'Oops',
                      icon: 'warning',
                      variant: 'danger',
                      text: `Hemos detectado problemas con la verificación de reCAPTCHA. \n \n Problema: ${err}`
                    }
                  })
                })
            }
          } else {
            this.disabledTooltip = false
            this.$refs.tooltipRestorePassword.$emit('open')
            setTimeout(() => {
              this.disabledTooltip = true
              this.$refs.tooltipRestorePassword.$emit('close')
            }, 5000)
          }
          
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:'Oops',
              icon: 'warning',
              variant: 'danger',
              text: 'Debes cumplir los requisitos mínimos para actualizar la contraseña'
            }
          })
        }
      })
    },
    restorePassword () {
      this.$root.$data.auth.confirmPasswordReset(this.oobCode, this.password)
        .then((resp) => {
          this.showAlert = true
          this.messageAlert = {
            text: 'Se ha actualizado correctamente tu contraseña.',
            type: this.CONDITIONS.SUCCESS,
            title: 'Recuperar Contraseña'
          }
          setTimeout(() => {
            this.authenticationLogin()
          }, 5000)
        })
        .catch((error) => {
          this.showAlert = true
          this.messageAlert = {
            error: true,
            invalidOobCode: ['auth/expired-action-code'].includes(error.code),
            type: this.CONDITIONS.ERROR,
            title: 'Recuperar Contraseña'
          }
          console.error(error)
          setTimeout(() => {
            this.showAlert = false
            this.$router.push({ name: 'auth-login' })
          }, 5000)
        })
        .finally(_ => {
          this.showRecaptcha('recaptcha', this.attempt, 0)
          this.loading = false
        })
    },
    authenticationLogin () {
      this.$root.$data.auth
        .signInWithEmailAndPassword(this.userEmail, this.password)
        .then((data) => {
          this.showAlert = false
          this.$root.$data.auth.setPersistence(this.$root.$data.fb.auth.Auth.Persistence.LOCAL)
          this.loggedUser = data.user
          return data.user.getIdToken()
        })
        .then((token) => {
          this.$store.dispatch('fetchUserData', {id: this.loggedUser.uid, token, from: 'login', remember: this.status})
        })
        .catch((err) => {
          this.showAlert = false
          this.showRecaptcha('recaptcha', this.attempt, 0)
          this.loading = false
          console.error(err)
          let message = err.message
          if (['auth/wrong-password', 'auth/user-not-found'].includes(err.code)) {
            message = 'Correo o contraseña incorrectos'
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:'Error',
              icon: 'WarningIcon',
              variant: 'danger',
              text: message
            }
          })
        })
    }
  }
}
</script>
<style lang="scss">
.mb-custom-forgot {
  margin-bottom: 15px;
}
.container-recovery-password-internal h2, .container-recovery-password-internal p{
  color: #18195E;
}
.container-recovery-password-internal h2 {
  margin-bottom: 1rem; 
  font-weight: 600;
}
.container-recovery-password-internal p {
  font-size: 13px;
}
.container-recovery-password-internal img  {
    margin-bottom: 2rem;
    width: 60%;
}
.customize-input, .customize-input:focus, .customize-input .input-group-text {
    background: #F0EFF8;
    color: #49454F;
}
.customize-input::placeholder { 
    color: #39549F; 
}
.flex {
    display: flex;
}
.center-recaptcha {
    justify-content: center;
    margin: 3rem 0;
}

#container-recovery-password, #alert-recovery{
    background-color: #FFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 100vh;
}
.icon-lock {
    margin-left: 0.5rem;
    color: #F4BB43;
}
.form-reset-password {
    margin-top: 3.5rem;
}
.container-recovery-password-internal {
    width: 450px;
}
.btn-reset {
    background: linear-gradient(90deg, #F49143 -21.52%, #F4BB43 104.43%);
    color: #FFFFFF;
    border: none;
    height: 3rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
}
.btn-reset:hover {
    background: linear-gradient(90deg, #f5852c -21.52%, #f7b52c 104.43%);
    color: #FFFFFF;
}
.error-message {
  font-size: 14px;
}
@-webkit-keyframes fold {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    }
@keyframes fold {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.form-tooltip {
    display: block;
    visibility: hidden;
    box-sizing: border-box;
    height: 0;
    margin-bottom: -8px;
    padding: 4px 16px;
}
:focus + .form-tooltip {
    margin-bottom: 0;
    height: auto;
    visibility: visible;
    -webkit-animation: fold ease 500ms;
            animation: fold ease 500ms;
    -webkit-animation-direction: normal;
            animation-direction: normal;
}
.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}
.custome-box {
    position: absolute;
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    left: 3rem;
    z-index: 3;
    padding: 10px;
    top: 2.5rem;
    box-shadow: 0 0 0.5px rgb(86 96 117 / 42%);
    
}
.custome-box ul {
    padding: 0
}
.custome-box ul li {
    list-style: none;
    font-size: 14px;
    margin-bottom: 0.5rem;
}
.custome-box ul li span {
    color: #868686;
}
.custome-box h3{
    color: #6C6C6C;
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0;
    font-size: 15px;
    font-weight: 600;
}       
.custome-box:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 1.5rem;
    border-bottom: 16px solid white;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    margin: -30px 0 0 40px;
    border-radius: 2rem 1rem 2px 2px;
}
.custom-drowdown {
    width: 100%;
}
.check-circle-confirm{
    color: #97BA25 !important;
}
#tooltip-restore-password {
  top: -8px !important;
}
#tooltip-restore-password .tooltip-inner {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.7rem;
  border: 1px solid #E2E8F0;
  background-color: #fff;
  color: #323232;
  box-shadow: 0 0 0.5px rgba(86, 96, 117, 0.42);
}
#tooltip-restore-password .arrow {
  top: 1px !important;
}
#tooltip-restore-password .arrow::before {
  border-bottom-color: #fff;
}

@media (max-width: 870px) {
    .container-recovery-password-internal {
        width: auto;
        padding: 0 2rem;
    }
    .container-recovery-password-internal h2 {
        font-size: 20px;
    }
    .center-recaptcha {
        margin: 2rem 0;
    }
    .mb-custom-forgot {
      margin-bottom: 0;
    }
    
}
</style>